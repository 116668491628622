<template>
  <li
    ref="slide"
    aria-roledescription="slide"
    role="group"
    class="AiCarousel-slide"
    :class="{
      'AiCarousel-slide--vertical': vertical,
      'AiCarousel-slide--current': current,
      'AiCarousel-slide--previous': previous,
      'AiCarousel-slide--next': next,
      'AiCarousel-slide--carousel-clickable': galleryOnCarouselClick,
    }"
    :style="style">
    <component
      :is="item.component"
      v-bind="item.props"
      :slide-index="slideIndex"
      :index="index"
      :current="current"
      :previous="previous"
      :next="next"
      :first="first"
      :last="last" />
  </li>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';

import type { AiCarouselItem } from './types';

interface Props {
  item: AiCarouselItem;
  index?: number;
  slideIndex?: number;
  order?: number;
  slideWidth?: number;
  gutter?: number;
  vertical?: boolean;
  current?: boolean;
  previous?: boolean;
  next?: boolean;
  first?: boolean;
  last?: boolean;
  galleryOnCarouselClick?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  gutter: 16,
  order: undefined,
  slideWidth: 1,
  index: undefined,
  slideIndex: undefined,
});

const style = computed<CSSProperties>(() => {
  const slideStyles: CSSProperties = {
    marginRight: `${props.gutter}px`,
    width: `${props.slideWidth * 100}%`,
  };

  const numberOfGutterInCarouselViewport = Math.round(1 / props.slideWidth) - 1;
  const numberOfSlideInCarouselViewport = Math.round(1 / props.slideWidth);

  slideStyles.width = `calc(${props.slideWidth * 100}% - (${
    props.gutter
  }px * ${numberOfGutterInCarouselViewport}) / ${numberOfSlideInCarouselViewport})`;

  if (props.vertical) {
    slideStyles.marginBottom = slideStyles.marginRight;
    delete slideStyles.marginRight;
  } else {
    slideStyles.order = props.order;
  }

  return slideStyles;
});
</script>

<style scoped lang="scss">
@use '~/assets/styles/utilities/functions' as func;
@use '~/assets/styles/utilities/mq';

.AiCarousel-slide {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.AiCarousel-slide--carousel-clickable {
  overflow: hidden;
  & > .AiImage {
    transition: transform 300ms;
  }

  &:hover > .AiImage {
    transform: scale(1.1);
  }
}
</style>
