<template>
  <div class="ProgressBar">
    <div
      ref="progessbar"
      class="ProgressBar-current"
      :style="currentStyle"
      role="progressbar"
      :aria-label="$t('accessibility.description.carouselProgressbar')"
      :aria-describedby="$t('accessibility.description.progressbar')" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  currentStep: { default: 1, type: Number },
  steps: { default: 1, type: Number },
});

const currentStyle = computed(() => {
  const singleStepSize = 100 / props.steps;
  const translation = singleStepSize * (props.currentStep - 1) * props.steps;

  return {
    transform: `translateX(${translation}%)`,
    width: `${singleStepSize}%`,
  };
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/colors';

.ProgressBar {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #b5b5b5;
}

.ProgressBar-current {
  position: absolute;
  width: 100%;
  margin-top: -1px;
  height: 3px;
  background-color: colors.$gold-700;
  transform: translateX(0%);
  transition: transform 0.2s;
}
</style>
