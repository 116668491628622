export type HandlerEvent = MouseEvent | TouchEvent;

export interface Position {
  x: number;
  y: number;
}

export function isTouchEvent(event: HandlerEvent): boolean {
  return event.type.startsWith('touch');
}

export function getEventPosition(event: HandlerEvent): Position {
  const isTouch = isTouchEvent(event);
  const position: Position = { x: 0, y: 0 };

  position.x = isTouch
    ? (event as TouchEvent).touches[0].clientX
    : (event as MouseEvent).clientX;
  position.y = isTouch
    ? (event as TouchEvent).touches[0].clientY
    : (event as MouseEvent).clientY;

  return position;
}
