<template>
  <div
    class="AiCarouselButton"
    :class="{
      [`AiCarouselButton--${variant}`]: Boolean(variant),
      'AiCarouselButton--vertical': vertical,
    }">
    <div class="AiCarouselButton-container">
      <button
        type="button"
        class="AiCarouselButton-left"
        tabindex="-1"
        @click="leftClick">
        <ai-icon name="chevron-left" :size="12" class="AiCarouselButton-icon" />
      </button>
      <button
        type="button"
        class="AiCarouselButton-right"
        tabindex="-1"
        @click="rightClick">
        <ai-icon
          name="chevron-right"
          :size="12"
          class="AiCarouselButton-icon" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import AiIcon from '../AiIcon/AiIcon.vue';

interface Props {
  hidden?: boolean;
  accessId?: string;
  variant?: 'outline' | undefined;
  vertical?: boolean;
}

interface Emits {
  (event: 'left-click'): void;
  (event: 'top-click'): void;
  (event: 'right-click'): void;
  (event: 'bottom-click'): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const leftClick = () => {
  emits('left-click');
  emits('top-click');
};
const rightClick = () => {
  emits('right-click');
  emits('bottom-click');
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/colors';
@use '~/assets/styles/utilities/functions' as func;

.AiCarouselButton {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 100px;
  background: colors.$gold-300;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  z-index: 1;

  &--outline {
    background: transparent;
    border-color: colors.$gold-200;
  }

  &--vertical {
    .AiCarouselButton-left {
      padding: func.calcRem(17) func.calcRem(16) 0 func.calcRem(18);
    }
    .AiCarouselButton-right {
      padding: 0 func.calcRem(16) func.calcRem(17) func.calcRem(18);
    }
  }
}

.AiCarouselButton-container {
  display: flex;
  flex-direction: row;
  gap: func.calcRem(12);

  .AiCarouselButton--vertical & {
    flex-direction: column;
  }
}

.AiCarouselButton-left {
  padding: func.calcRem(17) 0 func.calcRem(16) func.calcRem(17);
}
.AiCarouselButton-right {
  padding: func.calcRem(17) func.calcRem(16) func.calcRem(17) 0;
}

.AiCarouselButton-left,
.AiCarouselButton-right {
  display: inline-flex;
  .AiCarouselButton--vertical & span {
    display: inline-block;
    transform: rotate(90deg);
  }
}

.AiCarouselButton-icon {
  color: colors.$stratos-900;

  .AiCarouselButton--outline & {
    color: colors.$gold-300;
  }
}
</style>
