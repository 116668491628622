<template>
  <section
    v-if="items.length"
    :ref="accessId"
    class="AiCarousel"
    aria-roledescription="carousel"
    :class="{
      'AiCarousel--left': position === 'left',
      'AiCarousel--right': position === 'right',
      'AiCarousel--full': position === 'full',
    }">
    <ai-carousel-button
      v-if="!disableCarouselBehaviour && !hideNavigationButton"
      :access-id="accessId"
      class="Carousel-arrows"
      :style="arrowsStyle"
      @right-click="next"
      @left-click="previous" />

    <ul
      :id="accessId"
      ref="container"
      class="AiCarousel-container"
      aria-live="polite">
      <ai-carousel-slide
        v-for="(item, index) of collection"
        ref="slides"
        :key="`ai-carousel-slide-${item.key}-${index}`"
        :aria-label="`${index + 1} of ${collection.length}`"
        :gutter="gutter"
        :order="slideBuffer.indexOf(index + (1 % props.items.length))"
        :item="item"
        :index="index % props.items.length"
        :slide-index="index"
        :first="index === 0"
        :last="index === items.length - 1"
        :current="currentStep === index + 1"
        :previous="currentStep === index + 2"
        :next="currentStep === index"
        :slide-width="props.slideWidth"
        :gallery-on-carousel-click="galleryOnCarouselClick"
        :class="{
          'AiCarousel-slide--clickable': galleryOnCarouselClick && !hideGallery,
        }"
        @focus="handleSlideFocus(index)"
        @click="
          galleryOnCarouselClick && !hideGallery
            ? openMediaGalleryOnStep(index + 1)
            : undefined
        " />
    </ul>

    <div
      v-if="
        (!hideProgressBar && !disableCarouselBehaviour) ||
        (mobileButton && !disableCarouselBehaviour) ||
        !hideGallery ||
        $slots.action
      "
      class="Carousel-actions"
      :class="{
        'Carousel-actions--start': buttonPosition === 'start',
        'Carousel-actions--center': buttonPosition === 'center',
        'Carousel-actions--end': buttonPosition === 'end',
      }">
      <progress-bar
        v-if="!hideProgressBar && !disableCarouselBehaviour"
        :steps="items.length"
        :current-step="currentStep"
        :style="progressBarStyles"
        class="Carousel-progressBar" />

      <ai-carousel-button
        v-if="mobileButton"
        class="Carousel-mobileButton"
        :variant="mobileButtonVariant"
        @right-click="next"
        @left-click="previous" />

      <div v-if="buttonWithslot" class="Carousel-mobileButtonWithslot">
        <slot name="buttonWithslot" />

        <ai-carousel-button
          class="Carousel-mobileButton"
          :variant="mobileButtonVariant"
          @right-click="next"
          @left-click="previous" />
      </div>

      <slot name="action">
        <ai-button
          v-if="!hideGallery"
          :id="accessId + '-galleryButton'"
          class="Carousel-viewGallery"
          :variant="AiButtonVariant.Tertiary"
          :icon-right="
            // eslint-disable-next-line prettier/prettier
            'button-arrow-right' as IconName
          "
          :label="$t('ux.molecules.carousel.viewGallery')"
          :aria-label="$t('accessibility.description.buttonViewGallery')"
          slim
          small
          @click="emits('openMediaGallery')" />

        <ai-button
          v-if="!hideShowAll"
          class="Carousel-showAll"
          :variant="AiButtonVariant.Tertiary"
          :icon-right="
            // eslint-disable-next-line prettier/prettier
            'chevron' as IconName
          "
          :label="$t('ux.molecules.carousel.showAll')"
          :aria-label="$t('ux.molecules.carousel.showAll')"
          slim
          small
          @click="emits('showAll')" />
      </slot>
    </div>
  </section>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce.js';
import delay from 'lodash/delay.js';
import throttle from 'lodash/throttle.js';
import type { CSSProperties } from 'vue';

import { AiButtonVariant } from '../../atoms/AiButton/constants';
import type { IconName } from '../../atoms/AiIcon/types';
import type { HandlerEvent, Position } from '../../helpers/carousel';
import { getEventPosition, isTouchEvent } from '../../helpers/carousel';

import AiCarouselSlide from './AiCarouselSlide.vue';
import ProgressBar from './AiProgressBar.vue';
import { useInitialCarouselData } from './hooks';
import type { AiCarouselItem } from './types';

interface Props {
  accessId?: string;
  items: AiCarouselItem[];
  modelValue: number;
  animation?: number;
  dragTolerance?: number;
  enableDesktopDrag?: boolean;
  position?: 'full' | 'right' | 'left';
  buttonPosition?: 'start' | 'center' | 'end';
  slideWidth?: number;
  gutter?: number;
  hideGallery?: boolean;
  mobileButton?: boolean;
  buttonWithslot?: boolean;
  hideShowAll?: boolean;
  syncTireWithSlide?: boolean;
  hideProgressBar?: boolean;
  hideNavigationButton?: boolean;
  arrowsTopPosition?: string;
  arrowsRightPosition?: string;
  disableCarouselOnNotEnoughItems?: boolean;
  mobileButtonVariant?: 'outline' | undefined;
  buttonAccessibilityLabel?: string;
  galleryOnCarouselClick?: boolean;
  enableSlideOnFocus?: boolean;
}

interface Emits {
  (event: 'update:modelValue', step: number): void;
  (event: 'openMediaGallery'): void;
  (event: 'showAll'): void;
}

const props = withDefaults(defineProps<Props>(), {
  accessId: undefined,
  animation: 300,
  arrowsRightPosition: '',
  arrowsTopPosition: '10%',
  disableCarouselOnNotEnoughItems: true,
  dragTolerance: 0.85,
  enableDesktopDrag: false,
  gutter: 16,
  mobileButton: false,
  hideNavigationButton: false,
  hideProgressBar: false,
  hideShowAll: true,
  buttonPosition: 'start',
  position: 'right',
  slideWidth: 1,
  mobileButtonVariant: undefined,
  buttonAccessibilityLabel: undefined,
  enableSlideOnFocus: true,
});

const emits = defineEmits<Emits>();

const container = ref<HTMLElement>();

const currentStep = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const slideWidthInPercent = computed(() => props.slideWidth);
const disableCarouselOnNotEnoughItems = computed(
  () => props.disableCarouselOnNotEnoughItems,
);
const gutter = computed(() => props.gutter);
const arrowsStyle = computed<CSSProperties>(() => {
  const styles: CSSProperties = {};

  switch (props.position) {
    case 'left':
      styles.left = `-${gutter.value}px`;
      break;

    case 'right':
    case 'full':
      styles.right = `-${gutter.value}px`;
      break;
  }

  if (props.arrowsTopPosition) {
    styles.top = props.arrowsTopPosition;
  }

  if (props.arrowsRightPosition) {
    styles.right = props.arrowsRightPosition;
  }

  return styles;
});

const items = computed(() => props.items);

const {
  disableCarouselBehaviour,
  collection,
  middleTranslate,
  recomputeBuffer,
  slideBuffer,
  slideWidth,
  translateTo,
  refreshCollection,
} = useInitialCarouselData(items, {
  container,
  currentStep,
  disableCarouselOnNotEnoughItems,
  gutter,
  minimum: 10,
  position: props.position,
  slideWidthInPercent,
});

watch(items, refreshCollection);

const dragging = ref<boolean>(false);
const startPosition: Position = { x: 0, y: 0 };
const startStep = ref<number>(1);
const endPosition: Position = { x: 0, y: 0 };
const slidesDragged = ref<number>(0);
const dragDirection = ref<'x' | 'y' | null>(null);

const delayedUpdateSlideTabIndexes = (delayTiming = props.animation + 100) => {
  delay(() => {
    nextTick(() => {
      updateSlideTabIndexes();
    });
  }, delayTiming);
};

const delayedBufferAndMiddleTranslate = () => {
  delay(() => {
    nextTick(() => {
      recomputeBuffer();
      translateTo(middleTranslate.value);
    });
  }, props.animation + 20);
};

watch(currentStep, (newStep, oldStep) => {
  if (newStep === oldStep || dragging.value || disableCarouselBehaviour.value)
    return;

  const difference = oldStep - newStep;
  const isRoundingAround = Math.abs(difference) === props.items.length - 1;
  const direction = isRoundingAround ? Math.sign(difference) * -1 : difference;

  const offset = middleTranslate.value + slideWidth.value * direction;

  translateTo(offset, true);

  delayedBufferAndMiddleTranslate();

  delayedUpdateSlideTabIndexes();
});

onMounted(() => {
  container.value?.addEventListener('touchstart', handleDragStart, {
    passive: false,
  });
  if (props.enableDesktopDrag) {
    container.value?.addEventListener('mousedown', handleDragStart);
  }

  delayedUpdateSlideTabIndexes(props.animation + 20);
});

onBeforeUnmount(() => {
  container.value?.removeEventListener('touchstart', handleDragStart, {
    capture: false,
  });
  if (props.enableDesktopDrag) {
    container.value?.removeEventListener('mouseup', handleDragStart);
  }
});

const progressBarStyles = computed(() => ({
  marginLeft: props.syncTireWithSlide
    ? `${(100 - props.slideWidth * 100) / 2}%`
    : 0,
  width: props.syncTireWithSlide ? `${props.slideWidth * 100}%` : '100%',
}));

function handleDragStart(event: HandlerEvent) {
  if (dragging.value) return;

  const isTouch = isTouchEvent(event);
  const { x, y } = getEventPosition(event);
  startPosition.x = x;
  startPosition.y = y;
  startStep.value = currentStep.value;

  document.addEventListener(isTouch ? 'touchmove' : 'mousemove', handleDrag, {
    passive: false,
  });
  document.addEventListener(isTouch ? 'touchend' : 'mouseup', handleDragEnd);

  dragging.value = true;
}

const handleDrag = (event: HandlerEvent) => {
  if (dragDirection.value === 'y') {
    return;
  }

  const { x, y } = getEventPosition(event);
  endPosition.x = x;
  endPosition.y = y;

  const distanceX = x - startPosition.x;
  const distanceY = y - startPosition.y;

  if (
    Math.abs(distanceX) < Math.abs(distanceY) &&
    dragDirection.value === null
  ) {
    dragDirection.value = 'y';
    return;
  }

  dragDirection.value = 'x';
  event.preventDefault();

  const deltaX = middleTranslate.value + distanceX;

  const slideWidthTolerance = (1 - props.dragTolerance) * slideWidth.value;
  const effectiveDistance = Math.abs(distanceX) % slideWidth.value;

  if (effectiveDistance >= slideWidthTolerance) {
    const forward = Math.sign(distanceX) < 0;
    const roundingFunction = forward ? Math.floor : Math.ceil;

    slidesDragged.value =
      Math.abs(roundingFunction(distanceX / slideWidth.value)) *
      Math.sign(distanceX) *
      -1;
    let newStep = startStep.value + slidesDragged.value;

    if (newStep <= 0) {
      newStep = Math.min(newStep + props.items.length, props.items.length);
    } else if (newStep > props.items.length) {
      newStep = Math.max(newStep - props.items.length, 1);
    }

    currentStep.value = newStep;
  }

  translateTo(deltaX);
};

function handleDragEnd(event: HandlerEvent) {
  const isTouch = isTouchEvent(event);

  // Final snap to a proper slide
  const direction = Math.sign(startPosition.x - endPosition.x);
  translateTo(
    middleTranslate.value -
      Math.abs(slidesDragged.value * slideWidth.value) * direction,
    true,
  );

  slidesDragged.value = 0;

  startPosition.x = 0;
  startPosition.y = 0;
  endPosition.x = 0;
  endPosition.y = 0;

  // Wait for the snap animation to end to "reset" the buffer & default slide
  delay(() => {
    startStep.value = currentStep.value;
    recomputeBuffer();
    translateTo(middleTranslate.value);
  }, props.animation + 20);

  delayedUpdateSlideTabIndexes();

  dragging.value = false;
  dragDirection.value = null;

  document.removeEventListener(
    isTouch ? 'touchmove' : 'mousemove',
    handleDrag,
    {
      capture: false,
    },
  );
  document.removeEventListener(isTouch ? 'touchend' : 'mouseup', handleDragEnd);
}

const next = throttle(
  () => {
    currentStep.value =
      currentStep.value < props.items.length ? currentStep.value + 1 : 1;

    translateTo(middleTranslate.value - slideWidth.value, true);

    delayedBufferAndMiddleTranslate();
    delayedUpdateSlideTabIndexes();
  },
  props.animation + 20,
  { leading: true, trailing: false },
);

const previous = throttle(
  () => {
    currentStep.value =
      currentStep.value > 1 ? currentStep.value - 1 : props.items.length;

    translateTo(middleTranslate.value + slideWidth.value, true);

    delayedBufferAndMiddleTranslate();
    delayedUpdateSlideTabIndexes();
  },
  props.animation + 20,
  { leading: true, trailing: false },
);

const goTo = throttle(
  (index: number) => {
    currentStep.value = index;

    translateTo(middleTranslate.value + slideWidth.value, true);
  },
  props.animation + 20,
  { leading: true, trailing: false },
);

const handleSlideFocus = (index: number) => {
  if (!props.enableSlideOnFocus) {
    return;
  }
  if (index > 0 && index < props.items.length) {
    goTo(index);
  }
};

const slides = ref<ComponentPublicInstance[]>([]);
const updateSlideTabIndexes = debounce(() => {
  if (!slides.value.length) {
    return;
  }

  const recursiveSetTabindex = (el: Element) => {
    if (el.getAttribute('tabindex') !== '-2') {
      el.setAttribute('tabindex', '-1');
      Array.from(el.children).forEach(recursiveSetTabindex);
    }
  };

  const recursiveRemoveTabindex = (el: Element) => {
    if (el.hasAttribute('tabindex') && el.getAttribute('tabindex') === '-1') {
      el.removeAttribute('tabindex');
    }
    Array.from(el.children).forEach(recursiveRemoveTabindex);
  };

  let activeSlide: HTMLElement = slides.value[0].$el;
  slides.value.forEach(slide => {
    if (slide.$el.classList.contains('AiCarousel-slide--current')) {
      activeSlide = slide.$el;
    }
  });

  if (activeSlide) {
    slides.value.forEach(slide => {
      const slideElement = slide.$el;
      const width = window.innerWidth || document.documentElement.clientWidth;
      const slideRect = slideElement.getBoundingClientRect();

      if (
        Math.floor(slideRect.left) <= width &&
        Math.floor(slideRect.right) >=
          Math.floor(activeSlide.getBoundingClientRect().left)
      ) {
        recursiveRemoveTabindex(slideElement);
        slideElement.setAttribute('tabindex', '0');
      } else {
        recursiveSetTabindex(slideElement);
      }
    });
  }
}, 200);

const openMediaGalleryOnStep = (step: number) => {
  currentStep.value = step;
  emits('openMediaGallery');
};

if (process.client) {
  window.addEventListener('resize', updateSlideTabIndexes);
}

onBeforeUnmount(() => {
  if (process.client) {
    window.removeEventListener('resize', updateSlideTabIndexes);
  }
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/constants';

.AiCarousel {
  position: relative;
  &--right {
    clip-path: inset(-100vw -100vw -100vw 0);
  }

  &--left {
    clip-path: inset(-100vw 0 -100vw -100vw);
  }

  &--full {
    clip-path: unset;
  }
}

.Carousel-arrows {
  display: none;
  position: absolute;
  // Margin + size of view gallery - Desktop only
  top: calc(50% - (constants.$margin-04 + constants.$margin-02));
  transform: translateX(50%);

  .AiCarousel--left & {
    transform: translateX(-50%);
  }

  @media (mq.$from-medium) {
    display: flex;
  }
}

.AiCarousel-container {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
}

.AiCarousel-slide--clickable {
  cursor: pointer;
}

.Carousel-actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: constants.$margin-03;
  justify-content: center;

  @media (mq.$from-medium) {
    margin-top: constants.$margin-05;
    justify-content: row;
    flex-direction: row;
  }

  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }
}

.Carousel-progressBar {
  margin-bottom: constants.$margin-01;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}

.Carousel-viewGallery {
  flex-shrink: 0;
  @media (mq.$from-medium) {
    margin-left: constants.$margin-03;
  }

  @media (mq.$upto-medium) {
    margin-top: constants.$margin-03;
  }
}

.Carousel-showAll {
  @media (mq.$from-medium) {
    margin-left: constants.$margin-03;
  }

  @media (mq.$upto-medium) {
    margin-top: constants.$margin-03;
  }
}

.Carousel-mobileButton {
  margin-bottom: constants.$margin-01;

  @media (mq.$from-medium) {
    display: none;
  }
}

.Carousel-mobileButtonWithslot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (mq.$from-medium) {
    flex-basis: 25%;
  }
}
</style>
